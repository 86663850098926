import { AuthPipeGenerator, } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';

import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { SessionExpirationGuard } from './session-expiration/session-expiration.guard';

const redirectToHome: AuthPipeGenerator = () => redirectLoggedInTo(['inicio']);
const redirectToLogin: AuthPipeGenerator = () => redirectUnauthorizedTo(['login']);

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    ...canActivate(redirectToHome),
    loadComponent: () => import('./login/login.page').then(m => m.LoginRegisterPage),
  },
  {
    path: '',
    ...canActivate(redirectToLogin),
    canActivateChild: [SessionExpirationGuard],
    children: [
      {
        path: 'areas',
        loadComponent: () => import('./areas/areas.page').then(m => m.AreasPage)
      },
      {
        path: 'graficos/:deviceId',
        loadComponent: () => import('./new-area/new-area.page').then(m => m.NewAreaPage)
      },
      {
        path: 'alertas',
        loadComponent: () => import('./alerts/alerts.page').then(m => m.AlertsPage)
      },
      {
        path: 'alertas/configuracion',
        loadComponent: () => import('./configure-alerts/configure-alerts.page').then(m => m.ConfigureAlertPage)
      },
      {
        path: 'usuarios',
        loadComponent: () => import('./users/users.page').then(m => m.UsersPage)
      },
      {
        path: 'organizaciones',
        loadComponent: () => import('./organizations/organizations.page').then(m => m.OrganizationsPage)
      },
      {
        path: 'proximamente',
        loadComponent: () => import('./soon/soon.page').then(m => m.SoonPage)
      },
      {
        path: 'perfil/:username',
        loadComponent: () => import('./profile/profile.page').then(m => m.ProfilePage)
      },
      {
        path: 'manuales',
        loadComponent: () => import('./manuals/manuals.page').then(m => m.ManualsPage)
      },
      {
        path: 'dispositivos',
        loadComponent: () => import('./devices/devices.page').then(m => m.DevicesPage)
      },
      {
        path: 'inicio',
        loadComponent: () => import('./start/start.page').then(m => m.StartPage)
      },
      {
        path: 'soporte',
        loadComponent: () => import('./support/support.page').then(m => m.SupportPage)
      },
      {
        path: 'facturacion',
        loadComponent: () => import('./billing/billing.page').then( m => m.BillingPage)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
